import React from 'react';
import { I, Company, Role, Social, Reserved, Tech, Tech1, Tech2, Cursor } from './styles';

const App = () => (
  <div className="App">
    <h1><I>edivan@galindo&#9889;</I>&gt;<Cursor /></h1>
    {/* <h2><Role>software engineer</Role> | <Role>security engineer</Role> at <Company>Grupo Boticário</Company></h2> */}
    <h2><Role>offensive security engineer</Role> at <Company>Grupo Boticário</Company></h2>
    <div>
      <ul><b><Reserved>where</Reserved> am <I>I</I></b></ul>
      <li>
        <a target="_blank" href="https://hackerone.com/edivan"><Social>hackerone</Social></a>
      </li>
      <li>
        <a target="_blank" href="https://github.com/edivangalindo"><Social>github</Social></a>
      </li>
      <li>
        <a target="_blank" href="https://www.linkedin.com/in/edivandebarrosgalindo/"><Social>linkedin</Social></a>
      </li>
      {/* <li>
          <a target="_blank" href="https://www.instagram.com/edivanbarros/"><Social>instagram</Social></a>
        </li> */}
      <li>
        <a target="_blank" href="mailto:edivan.galindo@proton.me"><Social>send me an email</Social></a>
      </li>
    </div>
    <ul>
      <b><I>I</I> like <Reserved>that</Reserved></b>
      <li><Tech>hacking</Tech></li>
      <li><Tech1>golang</Tech1></li>
      <li><Tech2>dotnet</Tech2></li>
      <li><Tech>js</Tech></li>
      <li><Tech1>docker</Tech1></li>
      <li><Tech2>functional programming</Tech2></li>
    </ul>
    <ul><b><Reserved>some</Reserved> thoughts</b></ul>
    <li><a target="_blank" href="https://www.linkedin.com/pulse/porque-micro-gerenciar-o-processo-de-desenvolvimento-edivan/">Porque micro gerenciar o processo de desenvolvimento de software é uma péssima ideia</a></li>
  </div>
);

export default App;
