import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    color: #f9fbff;
    background-color: #1A1B1E;
    padding: 32px;
    line-height: 1.4;
    font-family: 'JetBrains Mono';
  }

  h2 {
    margin: 0;
    font-size: 14px;
  }

  p {
    margin: 4px;
  }

  ul {
    padding: 0;
  }

  li {
    margin-left: 32px;
    padding: 2px 0;
  }

  a {
    color: #f9fbff;
    border-bottom: 2px solid #f9fbff;
    padding: 0 4px;
    text-decoration: none;
        
  }

  a:hover {
    color: #313131;
    background-color: #f9fbff;
  }
`;

export default GlobalStyle;