import style from 'styled-components';

export const I = style.span`
  color: #A0F093;
`;

export const Company = style.span`
  color: #A683DC;
`;

export const Role = style.span`
  color: #E6EF87;
`;

export const Social = style.span`
  color: #F374BD;
`;

export const Reserved = style.span`
  color: #84DDF0;
`;

export const Tech = style.span`
  color: #50fa7b;
`;

export const Tech1 = style.span`
  color: #ffb86c;
`;

export const Tech2 = style.span`
  color: #ff79c6;
`;

export const Cursor = style.span`
  box-sizing: border-box;
  border-left: .5em solid;
  animation: blinking 1s infinite;
  animation-fill-mode: forwards;
  margin-left: 10px;
  
  @keyframes blinking {
    from, to { border-color: transparent; }
    50% { border-color: #50fa7b; }
  }
`;